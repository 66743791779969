import { useEventsContext } from "~/contexts/EventsContext";
import { urlRs } from "~/utils/url";
import ArticleCard from "~/components/Article/ArticleCard";
import { For } from "solid-js";

import type { ItemReference } from "~/types/drupal_jsonapi";

import "./FrontArticles.css";

export default function FrontArticles(props: {
  articles: ItemReference[];
  title: string;
}) {
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <section class="front-articles" data-test="articles-promoted">
        <h2 class="h2" innerHTML={props.title} />

        <div class="articles-list" data-test="articles">
          <For each={props.articles}>
            {(article) => <ArticleCard nid={article.id} item={article} />}
          </For>
        </div>

        <div class="more-link">
          <a
            onClick={() => sendClick("btn-articles", "articles")}
            href={urlRs("articles", "/le-neuf-decrypte/l-actu-du-neuf/")}
            data-test="btn-more"
          >
            Plus d’articles
          </a>
        </div>
      </section>
    </>
  );
}
